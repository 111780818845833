import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { statesArray } from '../../helpers/StateHelper';

export default function StateSelector({ onChange, value }) {
  return (
    <Select
      value={value}
      name="state"
      onChange={(event) => onChange(event.target.value)}
      sx={{ minWidth: 240, maxWidth: '100%' }}
    >
      {statesArray.map((state) => (
        <MenuItem value={state.value} key={state.value}>
          {state.name}
        </MenuItem>
      ))}
    </Select>
  );
}

StateSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
