import PropTypes from 'prop-types';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Card, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import { bgBlur } from 'src/theme/css';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import PetConfirmTransferDialog from './PetTransferDialog/pet-confirm-transfer-dialog';

const StyledCard = styled(Card)(({ theme }) => ({
  ...bgBlur({ color: '#FFFFFF' }),
  position: 'relative',
  width: '100%',
  height: '100%',
  transformStyle: 'preserve-3d',
  transition: 'transform 0.6s',
  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
  '&.flipped': {
    transform: 'rotateY(180deg)',
  },
}));

export default function PetCard({ user, filteredAndSortedPets, updateFilteredAndSortedPets }) {
  const popover = usePopover();

  const {
    name,
    pet_passport_id,
    avatar_file_name,
    breed,
    gender,
    status,
    shelter_name_common,
    age,
  } = user;

  const statusToUse = status || 'adoptable';
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  return (
    <>
      <StyledCard sx={{ textAlign: 'center' }}>
        <Box sx={{ position: 'relative' }}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Label
              onClick={popover.onOpen}
              color={(statusToUse === 'adoptable' && 'info') || 'success'}
              endIcon={<Iconify icon="eva:more-vertical-fill" sx={{ height: '10px' }} />}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                position: 'absolute',
                top: '16px',
                zIndex: 9,
                ml: 2,
              }}
            >
              {statusToUse}
            </Label>
          </Box>

          <Image src={avatar_file_name} alt={avatar_file_name} ratio="1/1" />
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: 2, mb: 1.5 }}
        >
          <ListItemText
            primary={name}
            secondary={breed}
            primaryTypographyProps={{
              typography: 'chat_body',
              textAlign: 'left',
              color: '#345BFF',
              fontWeight: '800',
            }}
            secondaryTypographyProps={{
              typography: 'chat_author',
              component: 'span',
              textAlign: 'left',
              fontWeight: '400',
            }}
            sx={{ ml: 2 }}
          />{' '}
          {/* Empty spacer */}
          <div style={{ flex: 1 }} />
          <Image src="/assets/images/avatars/near.svg" sx={{ mr: 2.5, mb: 2.5 }} />
        </Stack>

        <Box
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          sx={{ py: 3, typography: 'subtitle1' }}
        >
          <div>
            <Typography variant="chat_caption" component="div" sx={{ mb: 0.5, color: '#808080' }}>
              GENDER
            </Typography>
            <Typography
              variant="chat_author"
              component="div"
              sx={{ mb: 0.5, color: 'black', fontWeight: '600' }}
            >
              {gender}
            </Typography>
          </div>

          <div>
            <Typography variant="chat_caption" component="div" sx={{ mb: 0.5, color: '#808080' }}>
              LIFE STAGE
            </Typography>

            <Typography
              variant="chat_author"
              component="div"
              sx={{ mb: 0.5, color: 'black', fontWeight: '600' }}
            >
              {age.life_stage}
            </Typography>
          </div>
        </Box>
      </StyledCard>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="bottom-center"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            setIsConfirmDialogOpen(true);
          }}
        >
          <Iconify icon="solar:eye-bold" />
          Transfer
        </MenuItem>

        <MenuItem
          disabled
          onClick={() => {
            popover.onClose();
            setIsEditDialogOpen(true);
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>

        <MenuItem
          disabled
          onClick={() => {
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Lock
        </MenuItem>
      </CustomPopover>

      <PetConfirmTransferDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        pet={user}
        new_status="adoptable"
        filteredAndSortedPets={filteredAndSortedPets}
        updateFilteredAndSortedPets={updateFilteredAndSortedPets}
      />
    </>
  );
}

PetCard.propTypes = {
  user: PropTypes.object,
  filteredAndSortedPets: PropTypes.array,
  updateFilteredAndSortedPets: PropTypes.func,
};
