import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { formatFileSize } from '../../../helpers/FileSizeHelper';
import Iconify from '../../../components/iconify';

export default function PetDialogDocumentCard({ file, onRemove }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '12px',
        backgroundColor: '#FCFCFC',
        border: '1px solid #111111',
        padding: '10px',
        marginRight: '10px',
        marginBottom: '10px',
        width: isMobile ? '100%' : 'auto', // Set width based on screen size
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
          {file.name}
        </Typography>
        <Typography variant="caption">{formatFileSize(file.size)}</Typography>
      </Box>
      <Box
        sx={{ cursor: 'pointer', width: '50px', display: 'flex', justifyContent: 'end' }}
        onClick={() => onRemove(file.name)}
      >
        <IconButton>
          <Iconify icon="eva:close-outline" sx={{ color: 'red' }} />
        </IconButton>
      </Box>
    </Box>
  );
}

PetDialogDocumentCard.propTypes = {
  file: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};
