import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

// @mui
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import { useCheckoutContext } from '../../checkout/context';

// ----------------------------------------------------------------------

export default function CartIcon({ totalItems, blinkCount }) {
  const location = useLocation();
  const isChatPage = location.pathname === '/chat';
  const checkoutPath = isChatPage ? paths.open_checkout : paths.product.checkout;

  const { onGoToFirstStep } = useCheckoutContext();

  useEffect(() => {
    let blinkInterval;
    if (blinkCount > 0) {
      // Blink effect logic
      blinkInterval = setInterval(() => {
        // Toggle the visibility of the icon by updating the state
      }, 500); // Adjust the interval duration as needed
    }

    return () => {
      clearInterval(blinkInterval);
    };
  }, [blinkCount]);

  return (
    <Box
      component={RouterLink}
      onClick={onGoToFirstStep}
      href={checkoutPath}
      sx={{
        right: 0,
        top: '50%',
        zIndex: 1000,
        display: 'flex',
        cursor: 'pointer',
        position: 'absolute',
        color: 'background.neutral',
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
        bgcolor: 'text.blue',
        padding: (theme) => theme.spacing(1, 3, 1, 2),
        boxShadow: (theme) => theme.customShadows.card,
        transition: (theme) => theme.transitions.create(['opacity']),
        '&:hover': { opacity: 0.72 },
      }}
    >
      <Badge
        showZero
        badgeContent={totalItems}
        color="error"
        max={99}
        sx={{ pointerEvents: 'none' }}
      >
        <Iconify icon="solar:cart-3-bold" width={24} sx={{ pointerEvents: 'none' }} />
      </Badge>
    </Box>
  );
}

CartIcon.propTypes = {
  totalItems: PropTypes.number,
  blinkCount: PropTypes.number.isRequired,
};
