import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { Fragment, useRef } from 'react';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PetDialogDocumentCard from './pet-dialog-document-card';

export default function PetUploadDocumentsDialogDisplay({ updateFilesFn, removeFileFn, files }) {
  const filesRef = useRef(null);

  const fileUploadButtonFn = () => {
    if (filesRef.current) {
      filesRef.current.click();
    }
  };

  return (
    <Stack spacing={2}>
      <hr style={{ borderColor: 'rgba(0,0,0,0.15)', width: '100%' }} />
      <Stack spacing={2} direction="row" sx={{ alignItems: 'center' }}>
        <Typography style={{ fontWeight: 'bold' }}>Pet Documents</Typography>
        <Button
          size="small"
          variant="contained"
          sx={{ width: '150px', height: '40px', backgroundColor: '#333333' }}
          onClick={fileUploadButtonFn}
        >
          Upload Documents
        </Button>
      </Stack>
      <input
        onChange={(e) => updateFilesFn(e.target.files)}
        type="file"
        ref={filesRef}
        style={{ display: 'none' }}
        multiple
      />
      {files.length > 0 && (
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          {files.map((file, index) => (
            <Fragment key={`${file.name}-${index}`}>
              <PetDialogDocumentCard onRemove={removeFileFn} file={file} />
            </Fragment>
          ))}
        </div>
      )}
      <hr style={{ borderColor: 'rgba(0,0,0,0.15)', width: '100%' }} />
    </Stack>
  );
}

PetUploadDocumentsDialogDisplay.propTypes = {
  updateFilesFn: PropTypes.func.isRequired,
  removeFileFn: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
};
