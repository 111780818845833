import PropTypes from 'prop-types';

export default function ErrorsDisplay({ errors }) {
  if (errors.length === 0) return null;

  return (
    <div>
      {errors.map((error) => (
        <div key={error} style={{ color: 'darkred', fontStyle: 'italic', fontSize: '10px' }}>
          {error}
        </div>
      ))}
    </div>
  );
}

ErrorsDisplay.propTypes = {
  errors: PropTypes.array.isRequired,
};
