export const useMockUser = [

  // carlos@petastic.com --
  {
    id: "bd78eb8c-ee95-4d92-b45a-aa8d77439e26",
    pid: '5ee2fd93bccd3286db09da9a',
    mNearPublicAddress: '416fb87e70e19cc52fd9ff28ce43cc8c3f3af33feae03a4d7ac73d6f6e9f36a1',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: "",
    email: "carlos@petastic.com",
    name: "Carlos Herrera",
    displayName: 'Carlos',
    company: "Petastic",
    role: "Ecosystem Admin",
    address: "360 NW 27th Ave",
    city: "Miami",
    state: "Florida",
    zipCode: "33127",
    country: "United States",
    phoneNumber: "310-880-8673",
    password: '********',
    photoURL: '',
    about: 'Animal advocate and foster parent.',
    systemRoles: ['admin'],
    affiliations: [


      {
        role: ['admin'],
        shelterId: '5ee5729d3f3d0869dafcdce6',
        shelterName: 'Girls Who Love Dogs',
        affiliateSystemRoles: ['superadmin']
      },

      {
        role: ['admin'],
        shelterId: '5ee8317f47b1688327911328',
        shelterName: 'Pups Without Borders',
        affiliateSystemRoles: ['superadmin']
      },

      {
        role: ['admin'],
        shelterId: '5ee8317f3b01686155338599',
        shelterName: 'Caring Songs Rescue',
        affiliateSystemRoles: ['superadmin']
      },

      {
        role: ['admin'],
        shelterId: '5ee7189bc4e771a4661a0233',
        shelterName: 'Eleventh Hour Rescue',
        affiliateSystemRoles: ['superadmin']
      },

      {
        role: ['admin'],
        shelterId: '5ee5b3223d16d5c0312edf88',
        shelterName: 'The Asher House',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5eee77204406e1c112eb98b8',
        shelterName: 'Furrytail Endings',
        affiliateSystemRoles: ['superadmin']
      },

      {
        role: ['admin'],
        shelterId: '5eeb3cf569ab192823ce234b',
        shelterName: 'Meow2theResQ',
        affiliateSystemRoles: ['superadmin']
      },

      {
        role: ['admin'],
        shelterId: '5eecee6cd8a515ee24d7ff83',
        shelterName: 'Animal Care Zone',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8318159e1688940607096',
        shelterName: 'Saving Baja Paws',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5eefb377ab02268590a1f0fc',
        shelterName: 'Lonely Paws Adoption Network',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee40c1885bdcf2a23570b79',
        shelterName: 'Rescue Lyfe',
        affiliateSystemRoles: ['superadmin']
      },

      {
        role: ['admin'],
        shelterId: '5ee831810691687089285749',
        shelterName: '716 Paws',
        affiliateSystemRoles: ['superadmin']
      },

      {
        role: ['admin'],
        shelterId: '5ee40f8988544169beaf4031',
        shelterName: 'Desert Dog Project',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee07873b9fabcf42d311c83',
        shelterName: 'Ace of Hearts Dog Rescue',
        affiliateSystemRoles: ['superadmin']
      },

      {
        role: ['admin'],
        shelterId: '5ee2f7d70f5f6f22a273f6df',
        shelterName: 'RFRD Academy Canine Sanctuary, Inc.',
        affiliateSystemRoles: ['superadmin']
      },

      {
        role: ['admin'],
        shelterId: '5ee83181b7b1685258481548',
        shelterName: 'Caffeinated Canine Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee831810d51681938591381',
        shelterName: 'Animal Farm Foundation, Inc.',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317fbe01686087521637',
        shelterName: 'Operation Liberation, Inc',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83181b841684112445898',
        shelterName: 'Rescue Texas',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee831813c71690000253930',
        shelterName: 'Sand Springs Animal Welfare',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe9318242a1713697029506',
        shelterName: 'Mal\'s House Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f1bb1682615084643',
        shelterName: 'Loving Pawsabilities',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281717304399426',
        shelterName: 'West Coast Cane Corso Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824291715703113284',
        shelterName: 'Top of The Hill Dog Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83180f121686526084263',
        shelterName: 'Animal Haven',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281712564008136',
        shelterName: 'Motivated-Ones Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f6501687352248090',
        shelterName: 'California Bully Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281715365900379',
        shelterName: 'New York Bully Crew',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83180fb01683673939629',
        shelterName: 'Strong Paws Rescue, Inc.',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83180f8a1683475024978',
        shelterName: 'Second Chance Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83180f271685767429993',
        shelterName: 'Muddy Paws Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824271705684215701',
        shelterName: 'Brixies Rescue Inc',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee831824261713886583600',
        shelterName: 'Rescue Tails',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281712849717961',
        shelterName: 'Fayetteville Animal Protection Society',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281711564491846',
        shelterName: 'Texas Animal Rescue Rehab',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f49f1683402638385',
        shelterName: 'Bubbles Dog Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f3ea1685197328376',
        shelterName: 'Pawsitive Beginnings',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee26ffc9806e2255757fb2c',
        shelterName: 'Hope and Friends Rescue',
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5eec2d46fa4f16cbb6eb4e56',
        shelterName: "Miko's Rescue",
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5eea12b1fd570ce9e3d203d9',
        shelterName: "Show Me Your Pitties Rescue",
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5eeeea3b94fee7562d5130c4',
        shelterName: "Gingeroo Animal Rescue Network",
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f49b1682590785768',
        shelterName: "Pup Culture Rescue",
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f5631689138219059',
        shelterName: "4ever Yours Rescue Inc",
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f24e1687303255024',
        shelterName: "Grandma Betty's Animal Rescue",
        affiliateSystemRoles: ['admin']
      },




    ],
    pets: []
  }
  ,

  // adena@petastic.com --
  {
    id: '09c464b5-75f5-42f6-b155-581642035f86',
    pid: '5ee52754302883acec80e933',
    mNearPublicAddress: 'e505362fd476dd644fd6b16ac1b4437626d1c2e6fb1f6de08adf03505cb1bb18',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'adena@petastic.com',
    name: 'Adena Lavin',
    displayName: 'Adena',
    company: 'Petastic',
    role: "Ecosystem Admin",
    address: '1010 Pet Ave',
    city: 'Miami',
    state: 'FL',
    zipCode: '33130',
    country: 'United States',
    phoneNumber: '+852 9612 6122',
    password: 'demo1234',
    photoURL: '',
    about: 'Animal advocate and foster parent.',
    systemRoles: ['admin'],
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee5729d3f3d0869dafcdce6',
        shelterName: 'Girls Who Love Dogs',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f47b1688327911328',
        shelterName: 'Pups Without Borders',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f3b01686155338599',
        shelterName: 'Caring Songs Rescue',
        affiliateSystemRoles: ['superadmin']
      },

      {
        role: ['admin'],
        shelterId: '5ee7189bc4e771a4661a0233',
        shelterName: 'Eleventh Hour Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee5b3223d16d5c0312edf88',
        shelterName: 'The Asher House',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5eee77204406e1c112eb98b8',
        shelterName: 'Furrytail Endings',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5eeb3cf569ab192823ce234b',
        shelterName: 'Meow2theResQ',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5eecee6cd8a515ee24d7ff83',
        shelterName: 'Animal Care Zone',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8318159e1688940607096',
        shelterName: 'Saving Baja Paws',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5eefb377ab02268590a1f0fc',
        shelterName: 'Lonely Paws Adoption Network',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee40c1885bdcf2a23570b79',
        shelterName: 'Rescue Lyfe',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee831810691687089285749',
        shelterName: '716 Paws',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee40f8988544169beaf4031',
        shelterName: 'Desert Dog Project',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee07873b9fabcf42d311c83',
        shelterName: 'Ace of Hearts Dog Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee2f7d70f5f6f22a273f6df',
        shelterName: 'RFRD Academy Canine Sanctuary, Inc.',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83181b7b1685258481548',
        shelterName: 'Caffeinated Canine Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee831810d51681938591381',
        shelterName: 'Animal Farm Foundation, Inc.',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317fbe01686087521637',
        shelterName: 'Operation Liberation, Inc',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83181b841684112445898',
        shelterName: 'Rescue Texas',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee831813c71690000253930',
        shelterName: 'Sand Springs Animal Welfare',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe9318242a1713697029506',
        shelterName: 'Mal\'s House Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f1bb1682615084643',
        shelterName: 'Loving Pawsabilities',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824291715703113284',
        shelterName: 'Top of The Hill Dog Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281717304399426',
        shelterName: 'West Coast Cane Corso Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83180f121686526084263',
        shelterName: 'Animal Haven',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281712564008136',
        shelterName: 'Motivated-Ones Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f6501687352248090',
        shelterName: 'California Bully Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281715365900379',
        shelterName: 'New York Bully Crew',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83180fb01683673939629',
        shelterName: 'Strong Paws Rescue, Inc.',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83180f8a1683475024978',
        shelterName: 'Second Chance Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83180f271685767429993',
        shelterName: 'Muddy Paws Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824271705684215701',
        shelterName: 'Brixies Rescue Inc',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee831824261713886583600',
        shelterName: 'Rescue Tails',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281712849717961',
        shelterName: 'Fayetteville Animal Protection Society',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281711564491846',
        shelterName: 'Texas Animal Rescue Rehab',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f49f1683402638385',
        shelterName: 'Bubbles Dog Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f3ea1685197328376',
        shelterName: 'Pawsitive Beginnings',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee26ffc9806e2255757fb2c',
        shelterName: 'Hope and Friends Rescue',
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5eec2d46fa4f16cbb6eb4e56',
        shelterName: "Miko's Rescue",
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5eea12b1fd570ce9e3d203d9',
        shelterName: "Show Me Your Pitties Rescue",
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5eeeea3b94fee7562d5130c4',
        shelterName: "Gingeroo Animal Rescue Network",
        affiliateSystemRoles: ['admin']
      }, {
        role: ['admin'],
        shelterId: '5ee8317f49b1682590785768',
        shelterName: "Pup Culture Rescue",
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f5631689138219059',
        shelterName: "4ever Yours Rescue Inc",
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f24e1687303255024',
        shelterName: "Grandma Betty's Animal Rescue",
        affiliateSystemRoles: ['admin']
      },


    ]
  }
  ,

  // josh@petastic.com --
  {
    id: '8fa589f2-a4a9-4aa2-83e1-7da7c058a440',
    pid: '5eee43ff30ff495b703d56ce',
    mNearPublicAddress: 'e505362fd476dd644fd6b16ac1b4437626d1c2e6fb1f6de08adf03505cb1bb19',
    status: 'active',
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'josh@petastic.com',
    name: 'Josh Bullock',
    displayName: 'Josh',
    company: 'Petastic',
    role: "Ecosystem Admin",
    address: '1010 Pet Ave',
    city: 'Miami',
    state: 'FL',
    zipCode: '33130',
    country: 'United States',
    phoneNumber: '+1 856-625-2834',
    password: 'demo1234',
    photoURL: '',
    about: 'Animal advocate and foster parent.',
    systemRoles: ['admin'],
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee5729d3f3d0869dafcdce6',
        shelterName: 'Girls Who Love Dogs',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f47b1688327911328',
        shelterName: 'Pups Without Borders',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f3b01686155338599',
        shelterName: 'Caring Songs Rescue',
        affiliateSystemRoles: ['superadmin']
      },

      {
        role: ['admin'],
        shelterId: '5ee7189bc4e771a4661a0233',
        shelterName: 'Eleventh Hour Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee5b3223d16d5c0312edf88',
        shelterName: 'The Asher House',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5eee77204406e1c112eb98b8',
        shelterName: 'Furrytail Endings',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5eeb3cf569ab192823ce234b',
        shelterName: 'Meow2theResQ',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5eecee6cd8a515ee24d7ff83',
        shelterName: 'Animal Care Zone',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8318159e1688940607096',
        shelterName: 'Saving Baja Paws',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5eefb377ab02268590a1f0fc',
        shelterName: 'Lonely Paws Adoption Network',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee40c1885bdcf2a23570b79',
        shelterName: 'Rescue Lyfe',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee831810691687089285749',
        shelterName: '716 Paws',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee40f8988544169beaf4031',
        shelterName: 'Desert Dog Project',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee07873b9fabcf42d311c83',
        shelterName: 'Ace of Hearts Dog Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee2f7d70f5f6f22a273f6df',
        shelterName: 'RFRD Academy Canine Sanctuary, Inc.',
        affiliateSystemRoles: ['superadmin']
      },

      {
        role: ['admin'],
        shelterId: '5ee83181b7b1685258481548',
        shelterName: 'Caffeinated Canine Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee831810d51681938591381',
        shelterName: 'Animal Farm Foundation, Inc.',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317fbe01686087521637',
        shelterName: 'Operation Liberation, Inc',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83181b841684112445898',
        shelterName: 'Rescue Texas',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee831813c71690000253930',
        shelterName: 'Sand Springs Animal Welfare',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe9318242a1713697029506',
        shelterName: 'Mal\'s House Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f1bb1682615084643',
        shelterName: 'Loving Pawsabilities',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824291715703113284',
        shelterName: 'Top of The Hill Dog Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281717304399426',
        shelterName: 'West Coast Cane Corso Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83180f121686526084263',
        shelterName: 'Animal Haven',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281712564008136',
        shelterName: 'Motivated-Ones Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f6501687352248090',
        shelterName: 'California Bully Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281715365900379',
        shelterName: 'New York Bully Crew',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83180fb01683673939629',
        shelterName: 'Strong Paws Rescue, Inc.',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83180f8a1683475024978',
        shelterName: 'Second Chance Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee83180f271685767429993',
        shelterName: 'Muddy Paws Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824271705684215701',
        shelterName: 'Brixies Rescue Inc',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee831824261713886583600',
        shelterName: 'Rescue Tails',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281712849717961',
        shelterName: 'Fayetteville Animal Protection Society',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5fe931824281711564491846',
        shelterName: 'Texas Animal Rescue Rehab',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f49f1683402638385',
        shelterName: 'Bubbles Dog Rescue',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f3ea1685197328376',
        shelterName: 'Pawsitive Beginnings',
        affiliateSystemRoles: ['superadmin']
      },
      {
        role: ['admin'],
        shelterId: '5ee26ffc9806e2255757fb2c',
        shelterName: 'Hope and Friends Rescue',
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5eec2d46fa4f16cbb6eb4e56',
        shelterName: "Miko's Rescue",
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5eea12b1fd570ce9e3d203d9',
        shelterName: "Show Me Your Pitties Rescue",
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5eeeea3b94fee7562d5130c4',
        shelterName: "Gingeroo Animal Rescue Network",
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f49b1682590785768',
        shelterName: "Pup Culture Rescue",
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f5631689138219059',
        shelterName: "4ever Yours Rescue Inc",
        affiliateSystemRoles: ['admin']
      },
      {
        role: ['admin'],
        shelterId: '5ee8317f24e1687303255024',
        shelterName: "Grandma Betty's Animal Rescue",
        affiliateSystemRoles: ['admin']
      },

    ]
  }
  ,

  // brixiesrescue@icloud.com --
  {
    id: 'e8ff5ef7-219e-4e94-8e68-422f343da925',
    pid: '5ee53887b19cdcded45113ca',
    mNearPublicAddress: 'null',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'brixiesrescue@icloud.com',
    name: '',
    displayName: 'Brixies Rescue',
    company: '',
    role: "User",
    address: '1234 Rescue Ave',
    city: 'New York',
    state: 'NY',
    zipCode: '10001',
    country: 'United States',
    phoneNumber: '',
    password: 'demo1234',
    photoURL: '',
    about: 'We rescue pets in need.',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5fe931824271705684215701',
        shelterName: 'Brixies Rescue Inc',
        affiliateSystemRoles: ['admin']
      }
    ]
  }
  ,

  // nicolec@animalhaven.org --
  {
    id: 'e0d54809-f3fe-4352-b237-f9bc245edfc9',
    pid: '5eebd9baa468ff23babad0dc',
    mNearPublicAddress: 'e43f2104635c62f96227ce5dc039d4cd28cc3b94f34b3935f26d972376c00c54',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'nicolec@animalhaven.org',
    name: '',
    displayName: 'Nicole',
    company: '',
    role: "User",
    address: '456 Rescue Blvd',
    city: 'New York',
    state: 'NY',
    zipCode: '10002',
    country: 'United States',
    phoneNumber: '',

    password: 'demo1234',
    photoURL: '',
    about: 'Animal lover and rescuer.',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee83180f121686526084263',
        shelterName: 'Anymal Haven',
        affiliateSystemRoles: ['admin']
      }
    ]
  }
  ,

  // kristink@animalhaven.org
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7z6',
    pid: '',
    mNearPublicAddress: '6acf976c4547a5f6add59bcbd80f53997eb79779a71bbe5a2f602ee365a6f675',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'kristink@animalhaven.org',
    name: '',
    displayName: 'Kristin',
    company: '',
    role: "User",
    address: '789 Rescue St',
    city: 'New York',
    state: 'NY',
    zipCode: '10003',
    country: 'United States',
    phoneNumber: '',
    password: 'demo1234',
    photoURL: '',
    about: 'Passionate about animal welfare.',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee83180f121686526084263',
        shelterName: 'Shelter Name 1',
        affiliateSystemRoles: ['admin']
      }
    ]
  }
  ,

  // motv8one@aol.com -- 
  {
    id: 'e09ac23f-e00a-4ed9-a3f8-ca7dc458adb0',
    pid: '5eedf19e2a063d99f413aab8',
    mNearPublicAddress: '6730f421370e81c581ee2877fd105ec22dc28783590534ee35b3929e617ccfb9',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'motv8one@aol.com',
    name: '',
    displayName: 'Motivated Rescue',
    company: '',
    role: "User",
    address: '1020 Rescue Ave',
    city: 'Los Angeles',
    state: 'CA',
    zipCode: '90001',
    country: 'United States',
    phoneNumber: '',
    password: 'demo1234',
    photoURL: '',
    about: 'Dedicated to saving animals.',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5fe931824281712564008136',
        shelterName: 'Motivated-Ones Rescue',
        affiliateSystemRoles: ['admin']
      }
    ]
  },

  // rescuetailsmb@gmail.com --
  {
    id: 'bda5f3d4-8aea-422e-bf69-a7a89f103cde',
    pid: '5eeeb0837c9b4c962c22d2ff',
    mNearPublicAddress: '',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'rescuetailsmb@gmail.com',
    name: '',
    displayName: 'Motivated Rescue',
    company: '',
    role: "Ecosystem Users",
    address: '1020 Rescue Ave',
    city: 'Los Angeles',
    state: 'CA',
    zipCode: '90001',
    country: 'United States',
    phoneNumber: '',
    password: 'demo1234',
    photoURL: '',
    about: 'Dedicated to saving animals.',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee831824261713886583600',
        shelterName: 'Rescue Tails',
        affiliateSystemRoles: ['admin']
      }
    ]
  },

  // jackie@fapspet.org -- 
  {
    id: '2a09adbe-a1a4-49a9-b7d8-ff111b1947f9',
    mNearPublicAddress: 'f89ecc199bdca4790045f4b6cc8aa797f3152dd12041e10f87271aa0921cbfb6',
    pid: '5ee87c7d2cc4c9f9bee43ea8',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'jackie@fapspet.org',
    name: '[Full Name]',
    displayName: '',
    company: '',
    role: "Ecosystem User",
    address: '1020 Rescue Ave',
    city: 'Los Angeles',
    state: 'CA',
    zipCode: '90001',
    country: 'United States',
    phoneNumber: '',
    password: 'demo1234',
    photoURL: '',
    about: 'Dedicated to saving animals.',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5fe931824281712849717961',
        shelterName: 'Fayetteville Animal Protection Society',
        affiliateSystemRoles: ['admin']
      }
    ]
  },

  // texasanimalrescuerehab@outlook.com' --
  {
    id: '55e66dd8-d94f-4f6e-98ac-baa5a1418d81',
    mNearPublicAddress: 'xxxx',
    pid: '5ee1a63c85a62cf889d2b149',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'texasanimalrescuerehab@outlook.com',
    name: '[Full Name]',
    displayName: '',
    company: '',
    role: "Ecosystem User",
    address: '1020 Rescue Ave',
    city: 'Los Angeles',
    state: 'CA',
    zipCode: '90001',
    country: 'United States',
    phoneNumber: '',
    password: 'demo1234',
    photoURL: '',
    about: 'Dedicated to saving animals.',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5fe931824281711564491846',
        shelterName: 'Texas Animal Rescue Rehab',
        affiliateSystemRoles: ['admin']
      }
    ]
  },

  // snl32692@gmail.com --
  {
    id: '1b11cc7d-86b2-41cf-8d50-dae47c9f5af7',
    mmainnetNearPublicAddress: 'ec06c616d04c40e28d6335b2045f2a7f3f0a992740ec5867262b0f107927c1a0',
    pid: '5eefa1e781636ab5ac165b7c',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'snl32692@gmail.com',
    name: '[Full Name',
    displayName: '',
    company: '',
    role: "Ecosystem User",
    address: '1020 Rescue Ave',
    city: 'Los Angeles',
    state: 'CA',
    zipCode: '90001',
    country: 'United States',
    phoneNumber: '',
    password: 'demo1234',
    photoURL: '',
    about: 'Dedicated to saving animals.',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5fe931824281711564491846',
        shelterName: 'Texas Animal Rescue Rehab',
        affiliateSystemRoles: ['admin']
      }
    ]
  },

  // meagan@strongpawsrescue.org --
  {
    id: '685ba2d8-2ab8-478a-b65b-45bfb016985b',
    mNearPublicAddress: '6fb865589bd69bd1ac01f0f5c331274156d79c4aeda4e3d6f57d570be327d321',
    pid: '5ee19f10ddf43e5b1b65f481',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'meagan@strongpawsrescue.org',
    name: 'Meagan Kobielski',
    displayName: '',
    company: '',
    role: "Ecosystem User",
    address: '1020 Rescue Ave',
    city: 'Los Angeles',
    state: 'CA',
    zipCode: '90001',
    country: 'United States',
    phoneNumber: '',
    password: 'demo1234',
    photoURL: '',
    about: 'Dedicated to saving animals.',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee83180fb01683673939629',
        shelterName: 'Strong Paws Rescue, Inc.',
        affiliateSystemRoles: ['admin']
      }
    ]
  },

  // lizzk@bubblesdogrescue.org --
  {
    id: '620ea98f-4736-4657-962e-5a06fc75561a',
    mNearPublicAddress: '135dad568da921e5196b290f2c6c6bc38bda71db361e029d3086121df154e471',
    pid: '5ee68558b71f6d4f6161695d',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'lizzk@bubblesdogrescue.org',
    name: '[Full Name]',
    displayName: '',
    company: '',
    role: "User",
    address: '1020 Rescue Ave',
    city: 'Los Angeles',
    state: 'CA',
    zipCode: '90001',
    country: 'United States',
    phoneNumber: '',
    password: 'demo1234',
    photoURL: '',
    about: 'Dedicated to saving animals.',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8317f49f1683402638385',
        shelterName: 'Bubbles Dog Rescue',
        affiliateSystemRoles: ['admin']
      }
    ]
  },

  // info@bubblesdogrescue.org --
  {
    id: '7714d623-47d5-4d24-9ff6-e568a66f5793',
    mNearPublicAddress: '5cd9e75bb194864db73ca6126e264a5c4009ee3a19319766ebfb6eebac0c3ca8',
    pid: '5ee7199ea08b1a13636441ac',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'info@bubblesdogrescue.org',
    name: '[Full Name]',
    displayName: '',
    company: '',
    role: "Ecosystem User",
    address: '1020 Rescue Ave',
    city: 'Los Angeles',
    state: 'CA',
    zipCode: '90001',
    country: 'United States',
    phoneNumber: '',
    password: 'demo1234',
    photoURL: '',
    about: 'Dedicated to saving animals.',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8317f49f1683402638385',
        shelterName: 'Bubbles Dog Rescue',
        affiliateSystemRoles: ['admin']
      }
    ]
  },

  // westcoastcanecorsorescue@gmail.com --
  {
    id: '309750ca-9fc8-42f7-a238-4f42c73225f1',
    mNearPublicAddress: 'c41cd612f2de5e969b34a88aecb2a51a94f1002a64b102b267ee2754f3180024',
    pid: '5eef9a9da2616c8a481547b7',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'westcoastcanecorsorescue@gmail.com',
    name: '[Full Name]',
    displayName: '',
    company: '',
    role: "Ecosystem User",
    address: '1020 Rescue Ave',
    city: 'Los Angeles',
    state: 'CA',
    zipCode: '90001',
    country: 'United States',
    phoneNumber: '',
    password: 'demo1234',
    photoURL: '',
    about: 'Dedicated to saving animals.',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5fe931824281717304399426',
        shelterName: 'West Coast Cane Corso Rescue',
        affiliateSystemRoles: ['superadmin']
      }
    ]
  },

  // pawsitivebeginningsla@gmail.com --
  {
    id: 'a88bf858-4db9-4171-a76d-1ae164174624',
    mNearPublicAddress: '7a50760f17a7a5bd1851b953d92365af2ebea63eae408b6fee7dc991da04d4b1',
    pid: '5ee86189b708885d79f09d1a',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'pawsitivebeginningsla@gmail.com',
    name: '[Full Name]',
    displayName: '',
    company: 'Pawsitive Beginnings',
    role: "Ecosystem User",
    address: '1020 Rescue Ave',
    city: 'Los Angeles',
    state: 'CA',
    zipCode: '90001',
    country: 'United States',
    phoneNumber: '',
    password: 'demo1234',
    photoURL: '',
    about: 'Dedicated to saving animals.',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8317f3ea1685197328376',
        shelterName: 'Pawsitive Beginnings',
        affiliateSystemRoles: ['superadmin']
      }
    ]
  },

  // topofthehilldogrescue@gmail.com --
  {
    id: '7ce82603-0e55-4753-ae83-a2e30e5c5bad',
    mNearPublicAddress: 'b9fc06d4369ccb134b567e828044e542e743cf7245befd04eb7faaee8fe7dcc9',
    pid: '5eeffc8b99714e68a62a66c1',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'topofthehilldogrescue@gmail.com',
    name: 'Max Tran',
    displayName: 'Max',
    company: 'Top of The Hull Dog Rescue',
    role: "Ecosystem User",
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5fe931824291715703113284',
        shelterName: 'Top of The Hill Dog Rescue',
        affiliateSystemRoles: ['admin']
      }
    ]
  },

  // lovingpawsabilitiesinc@gmail.com -- 
  {
    id: 'da64b2ba-26b1-4e67-8a3a-aab01000ffbc',
    mNearPublicAddress: '121d2232a2a152265c4c4ee790696349384f3cb2638fc925fe772ac3a8ae9663',
    pid: '5eed0c39f5d8d9bcec6505d7',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'lovingpawsabilitiesinc@gmail.com',
    name: 'Marina',
    displayName: 'Marina',
    company: 'Loving Pawsabilities',
    role: "User",
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8317f1bb1682615084643',
        shelterName: 'Loving Pawsabilities',
        affiliateSystemRoles: ['admin']
      }
    ]
  },

  // liliana_rios_13@yahoo.com --
  {
    id: '4f872ca5-7adc-49a4-af9e-8d00baa61692',
    mNearPublicAddress: 'null',
    pid: '5ee34e98afe7498fb5daf1f3',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'liliana_rios_13@yahoo.com',
    name: '',
    displayName: '',
    company: 'Mal\'s House Rescue',
    role: "User",
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5fe9318242a1713697029506',
        shelterName: 'Mal\'s House Rescue',
        affiliateSystemRoles: ['admin']
      }
    ]
  },

  // tlarvid@sandspringsok.org --
  {
    id: '15655b2d-0f67-4d51-9cb8-90456eeb9b1b',
    mNearPublicAddress: '',
    pid: '5ee831813c71690000223330',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'tlarvid@sandspringsok.org',
    name: 'Tracey Arvidson',
    displayName: 'Sand Springs Animal Welfare',
    company: '',
    role: "User",
    address: '8620 W 21st St',
    city: 'Sand Springs',
    state: 'OK',
    zipCode: '74063',
    country: 'USA',
    phoneNumber: 'n/a',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee831813c71690000253930',
        shelterName: 'Sand Springs Animal Welfare',
        affiliateSystemRoles: ['superadmin']
      }
    ]
  },

  // info@rescuetexas.org
  {
    id: '07c435ab-9e82-490a-84b6-3ffc96f280f5',
    mNearPublicAddress: 'xxx5ee',
    pid: '5ee2d5876e121cf9a9544ab3',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'info@rescuetexas.org',
    name: '',
    displayName: '',
    company: '',
    role: "User",
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee83181b841684112445898',
        shelterName: 'Rescue Texas',
        affiliateSystemRoles: ['superadmin']
      }
    ]
  }
  ,

  // rescuetexasdogs@gmail.com --
  {
    id: '3bfe6fbc-ed1e-4a34-81e1-b49dc587a7ec',
    mNearPublicAddress: 'xxx5ee83181b841684112445898',
    pid: '5ee6eb5a4485cb23426e52c8',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'rescuetexasdogs@gmail.com',
    name: '',
    displayName: '',
    company: '',
    role: "User",
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee83181b841684112445898',
        shelterName: 'Rescue Texas',
        affiliateSystemRoles: ['superadmin']
      }
    ]
  }
  ,

  // elizabeth@operationliberation.org --
  {
    id: '59653e48-58d7-412a-bd65-7f2eaeadeedd',
    mNearPublicAddress: 'e082f04f488261c50cafd681cacf51643dcc456a7183397515030e6702d75c25',
    pid: '5eed54afaed35bd2c80c9d8d',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'elizabeth@operationliberation.org',
    name: 'Elizabeth Jones',
    displayName: '',
    company: 'Operation Liberation, Inc',
    role: "User",
    address: '711 NE 16th Court',
    city: 'Naples',
    state: 'Fl',
    zipCode: '77521',
    country: 'USA',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8317fbe01686087521637',
        shelterName: 'Operation Liberation, Inc',
        affiliateSystemRoles: ['superadmin']
      }
    ]
  }
  ,

  // info@operationliberation.org --
  {
    id: 'f3b8b03f-3213-427b-9ca5-9dcad1b44bd8',
    mNearPublicAddress: 'xxx5ee8317fbe01686087521637',
    pid: '5ee3af18ce4006f47c597ec4',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'info@operationliberation.org',
    name: 'Elizabeth Jones',
    displayName: '',
    company: 'Operation Liberation, Inc',
    role: "User",
    address: '711 NE 16th Court',
    city: 'Naples',
    state: 'Fl',
    zipCode: '77521',
    country: 'USA',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8317fbe01686087521637',
        shelterName: 'Operation Liberation, Inc',
        affiliateSystemRoles: ['superadmin']
      }
    ]
  }
  ,

  // njuchem@animalfarmfoundation.org --
  {
    id: '27474aa6-0c7f-40c9-b458-44e1e70c95d3',
    mNearPublicAddress: 'cdbb271fc1751b6d28d2431a8e53a1440fe33e91bcc10f19a43ecc3c0281f7f4',
    pid: '5eeae3cff36b6e7ef0535eea',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'njuchem@animalfarmfoundation.org',
    name: 'Acadia Generelli',
    displayName: '',
    company: 'Animal Farm Foundation',
    role: "User",
    address: '433 Pugsley Hill Rd',
    city: 'Amenia',
    state: 'NY',
    zipCode: '12501',
    country: 'USA',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee831810d51681938591381',
        shelterName: 'Animal Farm Foundation, Inc.',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  // info@animalfarmfoundation.org --
  {
    id: '717cbfc1-c723-4b16-bc1b-939dff695233',
    mNearPublicAddress: 'xxxanimalfarm',
    pid: '5ee2457cfd47bca79fbade3f',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'info@animalfarmfoundation.org',
    name: 'Acadia Generelli',
    displayName: '',
    company: 'Animal Farm Foundation',
    role: "User",
    address: '433 Pugsley Hill Rd',
    city: 'Amenia',
    state: 'NY',
    zipCode: '12501',
    country: 'USA',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee831810d51681938591381',
        shelterName: 'Animal Farm Foundation, Inc.',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  // lauraamoreno@icloud.com --
  {
    id: '6de1e8a7-d299-4231-b69f-e9fa221636c5',
    mainnetNearPublicAddress: 'xxx5ee26ffc9806e2255757fb2c',
    pid: '5eeeaddd46cd5e093a521c1f',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'lauraamoreno@icloud.com',
    name: 'Laura Moreno',
    displayName: '',
    company: 'Hope and Friends Rescue',
    role: "User",
    address: '2772 Tropicana Dr',
    city: 'Riverside',
    state: 'CA',
    zipCode: '92504',
    country: 'USA',
    phoneNumber: '+1 (562) 745-7359',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee26ffc9806e2255757fb2c',
        shelterName: 'Hope and Friends Rescue',
        affiliateSystemRoles: ['admin']
      },
    ]
  }
  ,

  // hopeandfriendsrescue@icloud.com --
  {
    id: '27474aa6-0c7f-40c9-b458-44e1e70c95d3',
    mainnetNearPublicAddress: 'xxxx5ee26ffc9806e2255757fb2c',
    pid: '5ee61fad77c566a9c9efdc20',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'hopeandfriendsrescue@icloud.com',
    name: 'Laura Moreno',
    displayName: '',
    company: 'Hope and Friends Rescue',
    role: "User",
    address: '2772 Tropicana Dr',
    city: 'Riverside',
    state: 'CA',
    zipCode: '92504',
    country: 'USA',
    phoneNumber: '+1 (562) 745-7359',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee26ffc9806e2255757fb2c',
        shelterName: 'Hope and Friends Rescue',
        affiliateSystemRoles: ['admin']
      },
    ]
  }
  ,

  // hello@mikosrescuemission.org --
  {
    id: '7e474412-7562-43d9-af78-5b325ad7e4ce',
    mainnetNearPublicAddress: 'a350c52e6f8a5e13fcaa0c2f0820a27bd4338b34d9d1508e122eaf60f2176e0a',
    pid: '5eec878278f953acee0cba66',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'hello@mikosrescuemission.org',
    name: 'Laura Moreno',
    displayName: '',
    company: "Miko's Rescue",
    role: "User",
    address: '',
    city: 'Santa Clarita',
    state: 'CA',
    zipCode: '92504',
    country: 'USA',
    phoneNumber: '+1 (805) 205-6055',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5eec2d46fa4f16cbb6eb4e56',
        shelterName: "Miko's Rescue",
        affiliateSystemRoles: ['admin']
      },
    ]
  }
  ,

  // showmeyourpittiesrescue@gmail.com --
  {
    id: '27474aa6-0c7f-40c9-b458-44e1e70c95d3',
    mainnetNearPublicAddress: '8cfc9bbb369f1283ab038adc9a620925c6a4523c58dbf80b06c7de7dc992348b',
    pid: '5ee86e85eea48084e97eb25e',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'showmeyourpittiesrescue@gmail.com',
    name: '[Full Name]',
    displayName: '',
    company: "Show Me Your Pitties Rescue",
    role: "User",
    address: '42847 Ranch Club Road',
    city: 'Lake Hughes',
    state: 'CA',
    zipCode: '92504',
    country: 'USA',
    phoneNumber: '+1 (707) 494-3473',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5eea12b1fd570ce9e3d203d9',
        shelterName: "Show Me Your Pitties Rescue",
        affiliateSystemRoles: ['admin']
      }

    ]
  }
  ,

  // kristinaVerdile@gmail.com
  {
    id: 'acdbbd56-b339-4d72-8701-0499cd6e347a',
    mainnetNearPublicAddress: 'xxx5eeeea3b94fee7562d5130c4',
    pid: '5ee668eb1175a0af76f61d53',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'kristinaverdile@gmail.com',
    name: 'Kristina Verdile',
    displayName: '',
    company: "Gingeroo Animal Rescue Network",
    role: "User",
    address: '42847 Ranch Club Road',
    city: 'Lake Hughes',
    state: 'CA',
    zipCode: '92504',
    country: 'USA',
    phoneNumber: '+1 (707) 494-3473',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5eeeea3b94fee7562d5130c4',
        shelterName: "Gingeroo Animal Rescue Network",
        affiliateSystemRoles: ['admin']
      }
    ]
  }
  ,

  // hello@pupculturerescue.org -- 
  {
    id: '9589b630-5141-4bc0-b44b-914d9f683a6d',
    mainnetNearPublicAddress: 'eaa475079dfac37b2892bde2ff23bee969d5553fb3df06704cb6c549281c730e',
    pid: '5eee59286dff07f6b3729571',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'hello@pupculturerescue.org',
    name: 'Victoria Shaffer',
    displayName: '',
    company: "Pup Culture Rescue",
    role: "User",
    address: '404 N. Lake Ave',
    city: 'Pasadena',
    state: 'CA',
    zipCode: '91101',
    country: 'USA',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8317f49b1682590785768',
        shelterName: "Pup Culture Rescue",
        affiliateSystemRoles: ['admin']
      },
    ]
  }
  ,

  // 4everyoursrescue@gmail.com --
  {
    id: '2287ff77-0db4-4f54-aa7f-fcca4a266c50',
    mainnetNearPublicAddress: '60f877d9e4c6b9c9022c03576b2cb3244ba3e22165c92ba0e0e6684aebb8ed9a',
    pid: '5eeae4344eb34e6a5d131a6a',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: '4everyoursrescue@gmail.com',
    name: 'Divya Thompson',
    displayName: '',
    company: "4ever Yours Rescue Inc",
    role: "User",
    address: '',
    city: 'Georgetown',
    state: 'CA',
    zipCode: '',
    country: 'USA',
    phoneNumber: '+1 916-915-3613',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8317f5631689138219059',
        shelterName: "4ever Yours Rescue Inc",
        affiliateSystemRoles: ['admin']
      },
    ]
  }
  ,

  // adoptions@grandmabettysanimalrescue.com --
  {
    id: 'e61e0fd6-cf79-4b31-8da1-a0c0f0338145',
    mainnetNearPublicAddress: 'xxxx5ee8317f24e1687303255024',
    pid: '5eef70022c59bd5da1a1f20b',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'adoptions@grandmabettysanimalrescue.com',
    name: 'Lisa Betty',
    displayName: '',
    company: "Grandma Betty's Animal Rescue",
    role: "User",
    address: '',
    city: 'San Diego',
    state: 'CA',
    zipCode: '92129',
    country: 'USA',
    phoneNumber: '+1 (858) 649-9134',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8317f24e1687303255024',
        shelterName: "Grandma Betty's Animal Rescue",
        affiliateSystemRoles: ['admin']
      }
    ]
  }
  ,

  // adopt@caffeinatedcaninerescuetx.org --
  {
    id: '6f726e38-c147-4d8e-8076-6918854a81da',
    mainnetNearPublicAddress: 'xxx5ee83181b7b1685258481548',
    pid: '5eedf9cc4030b57461204eab',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'adopt@caffeinatedcaninerescuetx.org',
    name: 'Leah McMaster',
    displayName: '',
    company: 'Caffeinated Canine Rescue',
    role: "Ecosystem User",
    address: '2413 Goodrich',
    city: 'Pearland',
    state: 'TX',
    zipCode: '77581',
    country: 'USA',
    phoneNumber: '+1 (936) 419-1016',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee83181b7b1685258481548',
        shelterName: 'Caffeinated Canine Rescue',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  // rfrdacademy@gmail.com --
  {
    id: '48cb3488-6b8e-4c10-9d7a-af0865033449',
    mainnetNearPublicAddress: 'xxx5ee2f7d70f5f6f22a273f6df',
    pid: '5eea4d80c15ce09c6667532b',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'rfrdacademy@gmail.com',
    name: 'David Trinh',
    displayName: '',
    company: 'RFRD Academy Canine Sanctuary, Inc.',
    role: "Ecosystem User",
    address: '',
    city: '260 E. Virginia St',
    state: 'CA',
    zipCode: '95116',
    country: 'USA',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '408-504-7339',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee2f7d70f5f6f22a273f6df',
        shelterName: 'RFRD Academy Canine Sanctuary, Inc.',
        affiliateSystemRoles: ['superadmin']
      }
    ]
  }
  ,

  // aceofheartsmngr@gmail.com --
  {
    id: 'dc4229bd-9c85-4685-abae-a2af65090bdc',
    mainnetNearPublicAddress: 'xxx5ee07873b9fabcf42d311c83',
    pid: '5ee2cd331526afbe8143c2be',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'aceofheartsmngr@gmail.com',
    name: '',
    displayName: '',
    company: 'Ace of Hearts Dog Rescue',
    role: "Ecosystem User",
    address: '508 N. Doheny Dr.',
    city: 'Beverly Hills',
    state: 'CA',
    zipCode: '90213',
    country: 'USA',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee07873b9fabcf42d311c83',
        shelterName: 'Ace of Hearts Dog Rescue',
        affiliateSystemRoles: ['superadmin']
      }
    ]
  }
  ,

  // info@desertdogproject.org --
  {
    id: 'd4b43337-9399-4bd1-990d-0052d77e7c1f',
    mainnetNearPublicAddress: 'xxx5ee40f8988544169beaf4031',
    pid: '5eecd62291441f7acfe3aa40',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'info@desertdogproject.org',
    name: 'Julia Correll',
    displayName: '',
    company: 'Desert Dog Project',
    role: "Ecosystem User",
    address: '5532 E Woodridge Dt.',
    city: 'Scottsdale',
    state: 'AZ',
    zipCode: '85254',
    country: 'USA',
    phoneNumber: '+1 203 257 7129',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee40f8988544169beaf4031',
        shelterName: 'Desert Dog Project',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  //  volunteer@californiabullyrescue.org --
  {
    id: '8d24aafe-5504-4850-8067-b0eb6feef2d2',
    mainnetNearPublicAddress: 'xxx5ee8317f6501687352248090',
    pid: '5ee8a4dbff093569dd46ba8c',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'volunteer@californiabullyrescue.org',
    name: 'Patricia A Joyce',
    displayName: '',
    company: 'California Bully Rescue',
    role: "Ecosystem User",
    address: '1120 Chelsea Pl',
    city: 'Turlock',
    state: 'CA',
    zipCode: '95380',
    country: 'USA',
    phoneNumber: '+1 203 257 7129',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8317f6501687352248090',
        shelterName: 'California Bully Rescue',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  //  info@716paws.org --
  {
    id: '2d8b7a18-d888-46cc-a575-b33dc0925f9f',
    mainnetNearPublicAddress: 'xxx5ee831810691687089285749',
    pid: '5ee8a4dbff09359dd46ab8d',
    status: "active",
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'info@716paws.org',
    name: 'Allison Rosa',
    displayName: '',
    company: '716 Paws',
    role: "Ecosystem User",
    address: '368 Darwin Drive',
    city: 'Amherst',
    state: 'NY',
    zipCode: '14226',
    country: 'USA',
    phoneNumber: '716-864-5474',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee831810691687089285749',
        shelterName: '716 Paws',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  // sarah@bubblesdogrescue.org --
  {
    id: '226a1eb4-9293-4285-9b02-1e1f25cbab22',
    mainnetNearPublicAddress: 'xxx5ee8317f49f1683402638385',
    pid: '5ee344cdafa095bc672a2f71',
    status: 'active',
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'sarah@bubblesdogrescue.org',
    name: 'Sarah',
    displayName: '',
    company: 'Bubbles Dog Rescue',
    role: 'Ecosystem User',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8317f49f1683402638385',
        shelterName: 'Bubbles Dog Rescue',
        affiliateSystemRoles: ['superadmin']
      }
    ]
  }
  ,

  // marlene@bubblesdogrescue.org --
  {
    id: 'a72af472-ca90-40e5-97b5-3e7f3c944f71',
    mainnetNearPublicAddress: 'xxx5ee8317f49f1683402638385',
    pid: '5ee0a5e903f99fca397e47b1',
    status: 'active',
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'marlene@bubblesdogrescue.org',
    name: 'Marlene',
    displayName: '',
    company: 'Bubbles Dog Rescue',
    role: 'Ecosystem User',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8317f49f1683402638385',
        shelterName: 'Bubbles Dog Rescue',
        affiliateSystemRoles: ['superadmin']
      }
    ]
  }
  ,

  // therescuelyfe@gmail.com --
  {
    id: '9672588a-0c6d-4dc0-9936-883f195a7e4e',
    mainnetNearPublicAddress: 'xxx5ee40c1885bdcf2a23570b79',
    pid: '5eeea8c840a628f533a28b04',
    status: 'active',
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'therescuelyfe@gmail.com',
    name: 'Cali Trevino',
    displayName: '',
    company: 'Rescue Lyfe',
    role: 'Ecosystem User',
    address: '41995 Boardwal',
    city: 'Palm Desert',
    state: 'CA',
    zipCode: '92211',
    country: 'USA',
    phoneNumber: '+1 (909) 800-1836',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee40c1885bdcf2a23570b79',
        shelterName: 'Rescue Lyfe',
        affiliateSystemRoles: ['superadmin']
      }
    ]
  }
  ,

  // lonelypawsadoptions@gmail.com --
  {
    id: '82954ced-1ec4-4234-913d-3da127f471f0',
    mainnetNearPublicAddress: 'xxx5eefb377ab02268590a1f0fc',
    pid: '5eeab797f5cb34d99acb028c',
    status: 'active',
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'lonelypawsadoptions@gmail.com',
    name: 'Ria Radzik',
    displayName: '',
    company: 'Lonely Paws Adoption Network',
    role: 'Ecosystem User',
    address: '15 Codington Lane',
    city: 'Glen Gardner',
    state: 'New Jersey',
    zipCode: '08826',
    country: 'USA',
    phoneNumber: '+17325895382',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5eefb377ab02268590a1f0fc',
        shelterName: 'Lonely Paws Adoption Network',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  // tori@bajapaws.org --
  {
    id: '4e9e24cc-c0e3-4a8c-8dcb-b168065e26a3',
    mainnetNearPublicAddress: 'xxx5ee8318159e1688940607096',
    pid: '5ee35517a251d66d19fa79df',
    status: 'active',
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'tori@bajapaws.org',
    name: 'Victoria Timm',
    displayName: '',
    company: 'Saving Baja Paws',
    role: 'Ecosystem User',
    address: '1641 NE 143rd Ave',
    city: 'Portland',
    state: 'OR',
    zipCode: '97230',
    country: 'USA',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8318159e1688940607096',
        shelterName: 'Saving Baja Paws',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  // info@animalcarezone.org --
  {
    id: 'd9e80903-7417-4bba-bee5-8a41b64aa027',
    mainnetNearPublicAddress: 'xxx5eecee6cd8a515ee24d7ff83',
    pid: '5eeb369bd0ebfeab8201260a',
    status: 'active',
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'info@animalcarezone.org',
    name: '',
    displayName: '',
    company: 'Animal Care Zone',
    role: 'Ecosystem User',
    address: '5854 Thelma Ave',
    city: 'Buena Park',
    state: 'CA',
    zipCode: '90623',
    country: 'USA',
    phoneNumber: '+18188252596',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5eecee6cd8a515ee24d7ff83',
        shelterName: 'Animal Care Zone',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  // paulinak721@gmail.com --
  {
    id: 'b661bed8-f9ee-4028-8772-967266bb080b',
    mainnetNearPublicAddress: 'xxx5eeb3cf569ab192823ce234b',
    pid: '5ee1c35ad43ca04d595d128f',
    status: 'active',
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'paulinak721@gmail.com',
    name: 'Paulina Kaczmarczyk',
    displayName: '',
    company: 'Meow2theResQ',
    role: 'Ecosystem User',
    address: '349 E Grand St',
    city: 'Mount Vernon',
    state: 'NY',
    zipCode: '10552',
    country: 'USA',
    phoneNumber: '+19175155252',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5eeb3cf569ab192823ce234b',
        shelterName: 'Meow2theResQ',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  // divdiv365@gmail.com --
  {
    id: 'c7c5604f-32fd-42d3-be1c-50f472ffad6e',
    mainnetNearPublicAddress: 'c4db2fe689c85b224e870e337aeeb58d3d71e8466c95b8cac8d70202667ae64e',
    pid: '5ee910d19f010fc32f1ecdca',
    status: 'active',
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'divdiv365@gmail.com',
    name: 'Divya Cowgil',
    displayName: '',
    company: 'Furrytail Endings',
    role: 'Ecosystem User',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'USA',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5eee77204406e1c112eb98b8',
        shelterName: 'Furrytail Endings',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  // kellymiller@optonline.net --
  {
    id: 'cf2b6cd3-3d51-4ae6-b4d4-a8ac73123fbf',
    mainnetNearPublicAddress: 'xxx5ee7189bc4e771a4661a0233',
    pid: '5ee54c3b6c6ea8b4f20843d4',
    status: 'active',
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'kellymiller@optonline.net',
    name: 'Kelly Miller',
    displayName: '',
    company: 'Eleventh Hour Rescue',
    role: 'Ecosystem User',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'USA',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee7189bc4e771a4661a0233',
        shelterName: 'Eleventh Hour Rescue',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  // lee@theasherhouse.com --
  {
    id: 'b3ab3f42-fdbe-4ea3-ab3e-3788c279ca72',
    mainnetNearPublicAddress: 'xxx5ee5b3223d16d5c0312edf88',
    pid: '5ee06d0c4dc14f97b7b04639',
    status: 'active',
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'lee@theasherhouse.com',
    name: 'Lee Asher',
    displayName: '',
    company: 'EThe Asher House',
    role: 'Ecosystem User',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'USA',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee5b3223d16d5c0312edf88',
        shelterName: 'The Asher House',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  // abridgehomerescue@gmail.com -- 
  {
    id: '66eae4c7-9395-44c9-a719-fb76e0936d16',
    mainnetNearPublicAddress: 'b55bffcf5ca3433ed4e92a06e8c22fcac9b2a584c0f9631c70f1690cad762044',
    pid: '5ee6d9cc24dc2f34518fcca8',
    status: 'active',
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'abridgehomerescue@gmail.com',
    name: 'Caring Songs Rescue',
    displayName: '',
    company: '',
    role: 'Ecosystem User',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'USA',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8317f3b01686155338599',
        shelterName: 'Caring Songs Rescue',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  // evie@pupswithoutborders.org --
  {
    id: '615f25c5-9d9a-4d9e-9d9f-03c2cf2c4fe7',
    mainnetNearPublicAddress: '10355b78061cf0b7c2d7656b9d2e07f61333a5565181217ee89f4203653852ee',
    pid: '5eef6be3026e446fa12391b9',
    status: 'active',
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'evie@pupswithoutborders.org',
    name: '',
    displayName: '',
    company: 'Pups Without Borders',
    role: 'Ecosystem User',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'USA',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee8317f47b1688327911328',
        shelterName: 'Pups Without Borders',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }
  ,

  // gwldr@icloud.com --
  {
    id: 'bae04086-9177-466b-9cfd-22985b89f3d0',
    mainnetNearPublicAddress: 'xxx5ee5729d3f3d0869dafcdce6',
    pid: '5eee0ffd63474dbf3c36217d',
    status: 'active',
    isVerified: true,
    isPublic: true,
    avatarUrl: '',
    email: 'gwldr@icloud.com',
    name: '',
    displayName: '',
    company: 'Girls Who Love Dogs',
    role: 'Ecosystem User',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'USA',
    phoneNumber: '',
    password: '',
    photoURL: '',
    about: '',
    systemRoles: '',
    affiliations: [
      {
        role: ['admin'],
        shelterId: '5ee5729d3f3d0869dafcdce6',
        shelterName: 'Girls Who Love Dogs',
        affiliateSystemRoles: ['superadmin']
      },
    ]
  }

];




export function useMockedUser() {
  const user = {
    id: '2',
    legalName: 'Carlos Herrera',
    displayName: 'Me',
    email: 'carlosh11@gmail.com',
    role: "User",

    password: 'demo1234',
    photoURL: '',
    phoneNumber: '+1 310-880-8673',
    country: 'United States',
    address: '2900 NE 7TH Ave Unit 2006 ',
    state: 'Miami',
    city: 'FL',
    zipCode: '33137',
    about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
    roles: ['user', 'foster'],
    isPublic: true,
    affiliations: [
      {
        role: 'foster',
        shelterId: '5ee83180f121686526084263',
        shelterName: 'Animal Haven',
      },
      {
        role: 'foster',
        shelterId: '5fe931824281712564008136',
        shelterName: 'Motivated-Ones Rescue',
      },
      {
        role: 'foster',
        shelterId: '5ee8317f6501687352248090',
        shelterName: 'California Bully Rescue',
      },
      {
        role: 'foster',
        shelterId: '5fe931824281715365900379',
        shelterName: 'New York Bully Crew',
      },
      {
        role: 'foster',
        shelterId: '5ee83180fb01683673939629',
        shelterName: 'Strong Paws Rescue, Inc.',
      },
      {
        role: 'foster',
        shelterId: '5ee83180f8a1683475024978',
        shelterName: 'Second Chance Rescue',
      },
      {
        role: 'foster',
        shelterId: '5ee83180f271685767429993',
        shelterName: 'Muddy Paws Rescue',
      },
      {
        role: 'foster',
        shelterId: '5fe931824271705684215701',
        shelterName: 'Brixies Rescue Inc',
      },
    ],
  };

  const fetchai = {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
    displayName: 'Petastic Ai',
    email: 'demo@minimals.cc',
    password: 'demo1234',
    photoURL: '/assets/images/avatars/fetch_white.svg',
    photoDarkURL: '/assets/images/avatars/fetch_blue.svg',
    phoneNumber: '+40 777666555',
    country: 'United States',
    address: '90210 Broadway Blvd',
    state: 'California',
    city: 'San Francisco',
    zipCode: '94116',
    about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
    role: ['admin'],
    isPublic: true,
  };

  return { user, fetchai, useMockUser };
}
