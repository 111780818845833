import { useCallback } from 'react';
import fetch from 'node-fetch';

export default function useHandlePetAdoptionDev() {
  return useCallback(async (current_account_id, pet_information, new_owner_information) => {
    const requestData = {
      current_account_id,
      pet_information,
      new_owner_information,
    };

    // Make the API request to update pet adoption
    const response = await fetch(
      'https://uot4ttu72a.execute-api.us-east-1.amazonaws.com/default/handlePetAdoptionDev',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      }
    );

    return response.json();
  }, []);
}
