import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      // {
      //   subheader: t('overview'),
      //   items: [
      //     {
      //       title: t('app'),
      //       path: paths.dashboard.root,
      //       icon: ICONS.dashboard,
      //     },
      //     {
      //       title: t('ecommerce'),
      //       path: paths.dashboard.general.ecommerce,
      //       icon: ICONS.ecommerce,
      //     },
      //     {
      //       title: t('analytics'),
      //       path: paths.dashboard.general.analytics,
      //       icon: ICONS.analytics,
      //     },
      //     {
      //       title: t('banking'),
      //       path: paths.dashboard.general.banking,
      //       icon: ICONS.banking,
      //     },
      //     {
      //       title: t('booking'),
      //       path: paths.dashboard.general.booking,
      //       icon: ICONS.booking,
      //     },
      //     {
      //       title: t('file'),
      //       path: paths.dashboard.general.file,
      //       icon: ICONS.file,
      //     },
      //   ],
      // },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('parents'),
        items: [
          // USER
          {
            title: t('pets'),
            path: paths.dashboard.pet.cards,
            icon: ICONS.user,
            // children: [
            //   { title: t('profile'), path: paths.dashboard.user.root },
            //   { title: t('cards'), path: paths.dashboard.user.cards },
            //   { title: t('list'), path: paths.dashboard.user.list },
            //   { title: t('create'), path: paths.dashboard.user.new },
            //   { title: t('edit'), path: paths.dashboard.user.demo.edit },
            //   { title: t('account'), path: paths.dashboard.user.account },
            // ],
          },

          // PRODUCT
          // {
          //   title: t('product'),
          //   path: paths.dashboard.product.root,
          //   icon: ICONS.product,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.product.root },
          //     {
          //       title: t('details'),
          //       path: paths.dashboard.product.demo.details,
          //     },
          //     { title: t('create'), path: paths.dashboard.product.new },
          //     { title: t('edit'), path: paths.dashboard.product.demo.edit },
          //   ],
          // },

          // // ORDER
          // {
          //   title: t('order'),
          //   path: paths.dashboard.order.root,
          //   icon: ICONS.order,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.order.root },
          //     { title: t('details'), path: paths.dashboard.order.demo.details },
          //   ],
          // },

          // INVOICE
          // {
          //   title: t('invoice'),
          //   path: paths.dashboard.invoice.root,
          //   icon: ICONS.invoice,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.invoice.root },
          //     {
          //       title: t('details'),
          //       path: paths.dashboard.invoice.demo.details,
          //     },
          //     { title: t('create'), path: paths.dashboard.invoice.new },
          //     { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
          //   ],
          // },

          // TOUR
          // {
          //   title: t('tour'),
          //   path: paths.dashboard.tour.root,
          //   icon: ICONS.tour,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.tour.root },
          //     { title: t('details'), path: paths.dashboard.tour.demo.details },
          //     { title: t('create'), path: paths.dashboard.tour.new },
          //     { title: t('edit'), path: paths.dashboard.tour.demo.edit },
          //   ],
          // },

          // CHAT
          {
            disabled: true,
            title: t('PetasticAI'),
            path: paths.dashboard.chat,
            icon: ICONS.chat,
          },

          // FOOD
          {
            title: t('food'),
            path: '#',
            icon: ICONS.order,
            disabled: true,
          },

          // INSURANCE
          {
            title: t('insurance'),
            path: '#',
            icon: ICONS.blog,
            disabled: true,
          },

          // REWARDS
          {
            title: t('rewards'),
            path: '#',
            icon: ICONS.invoice,
            disabled: true,
          },

          // MEMBERSHIP
          {
            title: t('membership'),
            path: '#',
            icon: ICONS.job,
            disabled: true,
          },

          // INTEGRATIONS
          {
            title: t('integrations'),
            path: '#',
            icon: ICONS.tour,
            disabled: true,
          },

          // FILE MANAGER
          {
            title: t('file_manager'),
            path: '#',
            icon: ICONS.folder,
            disabled: true,
          },

          // MAIL
          // {
          //   title: t('mail'),
          //   path: paths.dashboard.mail,
          //   icon: ICONS.mail,
          //   info: <Label color="error">+32</Label>,
          // },

          // // CALENDAR
          // {
          //   title: t('calendar'),
          //   path: paths.dashboard.calendar,
          //   icon: ICONS.calendar,
          // },

          // // KANBAN
          // {
          //   title: t('kanban'),
          //   path: paths.dashboard.kanban,
          //   icon: ICONS.kanban,
          // },
        ],
      },

      // ORGANIZATIONS
      {
        subheader: t(t('organizations')),
        items: [
          // JOB
          {
            title: t('Locations'),
            path: paths.dashboard.org.root,
            icon: ICONS.dashboard,
            children: [
              { title: t('list'), path: paths.dashboard.org.root },
              // { title: t('details'), path: paths.dashboard.org.demo.details, disabled: true },
              // { title: t('create'), path: paths.dashboard.org.new, disabled: true },
              // { title: t('edit'), path: paths.dashboard.org.demo.edit, disabled: true },
            ],
          },

          // // PRODUCT
          // {
          //   roles: [t('admin')], // 'admin' is not included here
          //   title: t('products'),
          //   path: paths.dashboard.product.root,
          //   icon: ICONS.product,
          //   disabled: true,

          //   children: [
          //     { title: t('list'), path: paths.dashboard.product.root },
          //     {
          //       title: t('details'),
          //       path: paths.dashboard.product.demo.details, disabled: true,

          //     },
          //     {
          //       title: t('create'), path: paths.dashboard.product.new, disabled: true,
          //     },
          //     {
          //       title: t('edit'), path: paths.dashboard.product.demo.edit, disabled: true,
          //     },
          //   ],
          // },

          // // ORDER
          {
            roles: [t('admin')], // 'admin' is not included here
            title: t('orders'),
            path: paths.dashboard.order.root,
            icon: ICONS.order,
            disabled: true,
            children: [
              { title: t('list'), path: paths.dashboard.order.root },
              {
                title: t('details'),
                path: paths.dashboard.order.demo.details,
                disabled: true,
              },
            ],
          },
          // BLOG
          // {
          //   title: t('our pets'),
          //   path: paths.dashboard.orgpets.root,
          //   icon: ICONS.user,
          //   // children: [
          //   //   { title: t('list'), path: paths.dashboard.post.root },
          //   //   { title: t('details'), path: paths.dashboard.post.demo.details },
          //   //   { title: t('create'), path: paths.dashboard.post.new },
          //   //   { title: t('edit'), path: paths.dashboard.post.demo.edit },
          //   // ],
          // },
          // {
          //   // default roles : All roles can see this entry.
          //   // roles: ['user'] Only users can see this item.
          //   // roles: ['admin'] Only admin can see this item.
          //   // roles: ['admin', 'manager'] Only admin/manager can see this item.
          //   // Reference from 'src/guards/RoleBasedGuard'.
          //   title: t('item_by_roles'),
          //   path: paths.dashboard.permission,
          //   icon: ICONS.lock,
          //   roles: ['admin', 'manager'],
          //   caption: t('only_admin_can_see_this_item'),
          // },

          // {
          //   title: t('menu_level'),
          //   path: '#/dashboard/menu_level',
          //   icon: ICONS.menuItem,
          //   children: [
          //     {
          //       title: t('menu_level_1a'),
          //       path: '#/dashboard/menu_level/menu_level_1a',
          //     },
          //     {
          //       title: t('menu_level_1b'),
          //       path: '#/dashboard/menu_level/menu_level_1b',
          //       children: [
          //         {
          //           title: t('menu_level_2a'),
          //           path: '#/dashboard/menu_level/menu_level_1b/menu_level_2a',
          //         },
          //         {
          //           title: t('menu_level_2b'),
          //           path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
          //           children: [
          //             {
          //               title: t('menu_level_3a'),
          //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
          //             },
          //             {
          //               title: t('menu_level_3b'),
          //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
          //             },
          //           ],
          //         },
          //       ],
          //     },
          //   ],
          // },
          // {
          //   title: t('item_disabled'),
          //   path: '#disabled',
          //   icon: ICONS.disabled,
          //   disabled: true,
          // },
          // {
          //   title: t('item_label'),
          //   path: '#label',
          //   icon: ICONS.label,
          //   info: (
          //     <Label color="info" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
          //       NEW
          //     </Label>
          //   ),
          // },
          // {
          //   title: t('item_caption'),
          //   path: '#caption',
          //   icon: ICONS.menuItem,
          //   caption:
          //     'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
          // },
          // {
          //   title: t('item_external_link'),
          //   path: 'https://www.google.com/',
          //   icon: ICONS.external,
          // },
          // {
          //   title: t('blank'),
          //   path: paths.dashboard.blank,
          //   icon: ICONS.blank,
          // },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        roles: ['ecosystem_admin'], // Ensure this is set correctly
        subheader: t('admin - ecosystem '),
        items: [
          // PRODUCT
          {
            roles: ['ecosystem_admin'], // Ensure this is set correctly
            title: t('products'),
            path: paths.admin.product.root,
            icon: ICONS.product,
            children: [
              { title: t('list'), path: paths.admin.product.root },
              // {
              //   title: t('details'),
              //   path: paths.dashboard.product.demo.details, disabled: true,

              // },
              {
                title: t('create'),
                path: paths.dashboard.product.new,
                disabled: false,
              },
              // {
              //   title: t('edit'), path: paths.dashboard.product.demo.edit, disabled: true,
              // },
            ],
          },

          // // ORDER
          {
            roles: ['ecosystem_admin'], // Ensure this is set correctly
            title: t('orders'),
            path: paths.admin.order.root,
            icon: ICONS.order,
            children: [
              { title: t('list'), path: paths.admin.order.root },
              {
                title: t('details'),
                path: paths.dashboard.order.demo.details,
                disabled: true,
              },
            ],
          },

          // USER
          {
            roles: ['ecosystem_admin'], // Ensure this is set correctly
            title: t('users'),
            path: paths.dashboard.user.cards,
            icon: ICONS.user,
            children: [
              // { title: t('profile'), path: paths.dashboard.user.root },
              // { title: t('cards'), path: paths.dashboard.user.cards },
              {
                roles: ['ecosystem_admin'], // Ensure this is set correctly
                title: t('list'),
                path: paths.dashboard.user.list,
              },
              // { title: t('create'), path: paths.dashboard.user.new },
              // { title: t('edit'), path: paths.dashboard.user.demo.edit },
              // { title: t('account'), path: paths.dashboard.user.account },
            ],
          },

          // ORGS
          {
            roles: ['ecosystem_admin'], // Ensure this is set correctly
            title: t('orgs'),
            path: paths.dashboard.product.root,
            icon: ICONS.job,
            children: [
              { title: t('list'), path: paths.dashboard.org.admin.root },
              {
                title: t('details'),
                path: paths.dashboard.product.demo.details,
                disabled: true,
              },
              {
                title: t('create'),
                path: paths.dashboard.org.admin.new,
                disabled: false,
              },
              {
                title: t('edit'),
                path: paths.dashboard.product.demo.edit,
                disabled: true,
              },
            ],
          },

          // INVOICE
          // {
          //   title: t('invoice'),
          //   path: paths.dashboard.invoice.root,
          //   icon: ICONS.invoice,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.invoice.root },
          //     {
          //       title: t('details'),
          //       path: paths.dashboard.invoice.demo.details,
          //     },
          //     { title: t('create'), path: paths.dashboard.invoice.new },
          //     { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
          //   ],
          // },

          // TOUR
          // {
          //   title: t('tour'),
          //   path: paths.dashboard.tour.root,
          //   icon: ICONS.tour,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.tour.root },
          //     { title: t('details'), path: paths.dashboard.tour.demo.details },
          //     { title: t('create'), path: paths.dashboard.tour.new },
          //     { title: t('edit'), path: paths.dashboard.tour.demo.edit },
          //   ],
          // },

          // MAIL
          // {
          //   title: t('mail'),
          //   path: paths.dashboard.mail,
          //   icon: ICONS.mail,
          //   info: <Label color="error">+32</Label>,
          // },

          // // CALENDAR
          // {
          //   title: t('calendar'),
          //   path: paths.dashboard.calendar,
          //   icon: ICONS.calendar,
          // },

          // // KANBAN
          // {
          //   title: t('kanban'),
          //   path: paths.dashboard.kanban,
          //   icon: ICONS.kanban,
          // },
        ],
      },
    ],
    [t]
  );

  return data;
}
