import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
import StateSelector from '../StateSelector';
import PetTransferStatusDisplay from './pet-transfer-status-display';
import ErrorsDisplay from '../../../components/errors/ErrorsDisplay';
import PetUploadDocumentsDialogDisplay from './pet-upload-documents-dialog-display';
import useHandleFullAdoption from '../../../api/petastic/useHandleFullAdoption';

export default function PetConfirmTransferDialog({
  open,
  onClose,
  pet,
  filteredAndSortedPets,
  updateFilteredAndSortedPets,
}) {
  const [page] = useState(1);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [files, setFiles] = useState([]);

  const addFilesFn = (newFiles) => {
    const filesArray = Array.isArray(newFiles) ? newFiles : Array.from(newFiles);

    setFiles((prevFiles) => {
      const filteredNewFiles = filesArray.filter(
        (newFile) => !prevFiles.some((file) => file.name === newFile.name)
      );

      if (filteredNewFiles.length === 0) {
        return prevFiles;
      }

      return [...prevFiles, ...filteredNewFiles];
    });
  };

  const removeFileFn = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const [activeState, setActiveState] = useState('AL');

  const { name, pet_passport_id, type, breed, gender, avatar_file_name, age, shelter_name_common } =
    pet;

  const {
    handleFullAdoption,
    isCreationLoading,
    isCreationCompleted,
    isAdoptionLoading,
    isAdoptionCompleted,
    creationErrors,
    trueOnCloseFn,
  } = useHandleFullAdoption({
    activeState,
    filteredAndSortedPets,
    updateFilteredAndSortedPets,
    onClose,
    pet,
    pet_passport_id,
    shelter_name_common,
    avatar_file_name: pet.avatar_file_name,
    age: pet.age,
    breed: pet.breed,
    name: pet.name,
  });

  const onCloseFn = useCallback(() => {
    setFiles([]);
    trueOnCloseFn();
  }, [trueOnCloseFn]);

  const NewUserSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phoneNumber: Yup.string().required('Phone number is required'),
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    zipCode: Yup.string().required('Zip code is required'),
    // not required
    trial: Yup.string(),
    fallback: Yup.boolean(),
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    handleFullAdoption(data).then();
  });

  return (
    <Dialog open={open} fullScreen onClose={onCloseFn}>
      <DialogTitle>
        {(() => {
          switch (page) {
            case 1:
              return 'Transfer guardianship';
            case 2:
              return 'Review';
            default:
              return 'All Done';
          }
        })()}
      </DialogTitle>
      {page === 1 && (
        <Stack spacing={1}>
          <DialogContent sx={{ overflow: 'unset' }}>
            <Stack direction="column" spacing={1} sx={{ mb: 1 }}>
              <Typography style={{ fontWeight: 'bold' }}>I want to transfer:</Typography>
              <ListItemText
                primary={`${name} the ${type ? type.split(':').pop() : ''}`}
                secondary={`a ${breed}`}
                secondaryTypographyProps={{ component: 'span', mt: 0.5 }}
                sx={{ paddingLeft: '16px' }}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography style={{ fontWeight: 'bold' }}>To the following human: </Typography>
              <FormProvider methods={methods} onSubmit={onSubmit}>
                <Stack spacing={2}>
                  <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                    <RHFTextField name="firstName" label="First Name" sx={{ flex: 1 }} />
                    <RHFTextField name="lastName" label="Last Name" sx={{ flex: 1 }} />
                  </Stack>
                  <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                    <RHFTextField name="email" label="Email Address" sx={{ flex: 1 }} />
                    <RHFTextField name="phoneNumber" label="Phone Number" sx={{ flex: 1 }} />
                  </Stack>
                  <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                    <RHFTextField name="address" label="Address" sx={{ flex: 1 }} />
                    <RHFTextField name="city" label="City" sx={{ flex: 1 }} />
                    <StateSelector
                      onChange={(st) => setActiveState(st)}
                      value={activeState}
                      sx={{ flex: 1 }}
                    />
                    <RHFTextField name="zipCode" label="Zip/Code" sx={{ flex: 1 }} />
                  </Stack>
                  <PetUploadDocumentsDialogDisplay
                    updateFilesFn={addFilesFn}
                    removeFileFn={removeFileFn}
                    files={files}
                  />
                  {/* Rest of the form */}
                  <PetTransferStatusDisplay
                    petName={pet.name}
                    adoptionStatus={{
                      isLoading: isAdoptionLoading,
                      isCompleted: isAdoptionCompleted,
                    }}
                    creationStatus={{
                      isLoading: isCreationLoading,
                      isCompleted: isCreationCompleted,
                    }}
                  />
                  <Stack alignItems="flex-end" spacing={2} direction="row">
                    <LoadingButton
                      onClick={onCloseFn}
                      variant="outlined"
                      sx={{
                        backgroundColor: '#ff4d4d',
                        color: '#FFFFFF',
                        ':hover': { backgroundColor: '#900000', color: '#FFFFFF' },
                      }}
                      loading={isCreationLoading || isAdoptionLoading}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isCreationLoading || isAdoptionLoading}
                    >
                      Confirm Transfer
                    </LoadingButton>
                  </Stack>
                  <ErrorsDisplay errors={creationErrors} />
                </Stack>
              </FormProvider>
            </Stack>
          </DialogContent>
        </Stack>
      )}
      <DialogActions>{page === 3 && <Button onClick={onCloseFn}>Close</Button>}</DialogActions>
    </Dialog>
  );
}

PetConfirmTransferDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  pet: PropTypes.object,
  filteredAndSortedPets: PropTypes.array,
  updateFilteredAndSortedPets: PropTypes.func,
};
