import PropTypes from 'prop-types';
import '../LoadingAnimations.css';

export default function PetTransferStatusDisplay({ creationStatus, adoptionStatus, petName }) {
  return (
    <>
      {!creationStatus.isCompleted ? (
        <>
          {creationStatus.isLoading && !creationStatus.isCompleted && (
            <div className="loading-text">
              Checking account status<span className="dots">.</span>
            </div>
          )}
          {!creationStatus.isLoading && creationStatus.isCompleted && (
            <div className="loading-text">Account passed all checks!</div>
          )}
        </>
      ) : (
        <>
          {adoptionStatus.isLoading && !adoptionStatus.isCompleted && (
            <div className="loading-text">
              Transferring ownership of {petName}
              <span className="dots">.</span>
            </div>
          )}
          {!adoptionStatus.isLoading && adoptionStatus.isCompleted && (
            <div className="loading-text">Transfer of ownership completed!</div>
          )}
        </>
      )}
    </>
  );
}

PetTransferStatusDisplay.propTypes = {
  creationStatus: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isCompleted: PropTypes.bool.isRequired,
  }),
  adoptionStatus: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isCompleted: PropTypes.bool.isRequired,
  }),
  petName: PropTypes.string.isRequired,
};
