import { useCallback } from 'react';
import fetch from 'node-fetch';

export default function useCreatePetOwnerAccount() {
  return useCallback(
    async (
      userEmail,
      userPhoneNumber,
      userFirstName,
      userLastName,
      userAddress,
      userState,
      userZipcode
    ) => {
      const requestData = {
        email: userEmail,
        phoneNumber: userPhoneNumber,
        fName: userFirstName,
        lName: userLastName,
        address: userAddress,
        state: userState,
        zipCode: userZipcode,
      };

      // Make the API request to update pet adoption
      const response = await fetch(
        'https://uot4ttu72a.execute-api.us-east-1.amazonaws.com/default/handleCreateAccountDev',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        }
      );

      return response.json();
    },
    []
  );
}
