import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import useCreatePetOwnerAccount from './useCreatePetOwnerAccount';
import useHandlePetAdoptionDev from './useHandlePetAdoptionDev';
import { sendEmail } from '../petastic-api';

export default function useHandleFullAdoption({
  activeState,
  filteredAndSortedPets,
  updateFilteredAndSortedPets,
  onClose,
  pet,
  pet_passport_id,
  shelter_name_common,
  avatar_file_name,
  age,
  breed,
  name,
}) {
  const { id } = useParams();

  const [isAdoptionLoading, setIsAdoptionLoading] = useState(false);
  const [isCreationLoading, setIsCreationLoading] = useState(false);
  const [isAdoptionCompleted, setIsAdoptionCompleted] = useState(false);
  const [isCreationCompleted, setIsCreationCompleted] = useState(false);
  const [creationErrors, setCreationErrors] = useState([]);

  const handleCreatePetOwnerAccount = useCreatePetOwnerAccount();
  const handlePetAdoptionFn = useHandlePetAdoptionDev();

  const trueOnCloseFn = useCallback(() => {
    setIsCreationLoading(false);
    setIsAdoptionLoading(false);
    setIsCreationCompleted(false);
    setIsAdoptionCompleted(false);
    setCreationErrors([]);

    onClose();
  }, [onClose]);

  const handleFullAdoption = useCallback(
    async (data) => {
      setIsCreationLoading(true);
      setIsAdoptionLoading(true);
      setIsCreationCompleted(false);
      setIsAdoptionCompleted(false);
      setCreationErrors([]);

      try {
        await new Promise((resolve) => setTimeout(resolve, 500));

        const {
          data: { account_id: accountCreationId, message: accountCreationMessage } = {},
          errors: accountCreationErrors,
        } = await handleCreatePetOwnerAccount(
          data.email,
          data.phoneNumber,
          data.firstName,
          data.lastName,
          data.address,
          activeState,
          data.zipCode
        );

        setIsCreationLoading(true);

        if (accountCreationErrors.length > 0) {
          setIsCreationLoading(false);
          setCreationErrors(accountCreationErrors);
          return;
        }

        // New owner information for pet adoption
        const newOwnerInfo = {
          account_type: null,
          owner_id: accountCreationId,
          issuer: null,
          trial_period: data.trial,
          public_address: null,
          wallet_type: 'magic',
          location_zip: data.zipCode,
          email: data.email,
          to_name: data.userName,
          city: data.city,
          state: activeState,
          phone_number: data.phoneNumber,
        };

        setIsCreationLoading(false);
        setIsCreationCompleted(true);

        const petInformationData = {
          pet_passport_id,
          name,
          image: avatar_file_name,
          gender: pet.gender,
          age: age.life_stage,
          breed,
          adopted_from: shelter_name_common,
        };

        // Handle pet adoption
        const {
          errors: adoptionErrors,
          data: { message: adoptionMessage, pet: adoptionPet },
        } = await handlePetAdoptionFn(id, petInformationData, newOwnerInfo);

        setIsAdoptionLoading(true);

        if (adoptionErrors.length > 0) {
          setIsAdoptionLoading(false);
          setCreationErrors([adoptionMessage]);
          return;
        }

        setIsAdoptionLoading(false);
        setIsAdoptionCompleted(true);

        updateFilteredAndSortedPets(
          filteredAndSortedPets.map((p) =>
            p.pet_passport_id === adoptionPet.pet_passport_id ? adoptionPet : p
          )
        );

        // Prepare email notification details
        const emailDetails = {
          to_email: data.email,
          conversation_id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
          pet_passport_id,
          pet_name: pet.name,
          pet_avatar: pet.avatar_file_name,
          pet_breed: pet.breed,
          pet_gender: pet.gender,
          pet_age: pet.age.life_stage,
          shelter_name: pet.shelter_name_common,
          to_name: `${data.firstName} ${data.lastName}`,
          phone_number: data.phoneNumber,
        };

        const { success: emailSent, message: emailErrorMessage } = await sendEmail(emailDetails);

        if (!emailSent) {
          setCreationErrors([emailErrorMessage]);
          return;
        }

        trueOnCloseFn();
      } catch (error) {
        console.error('An unexpected error occurred:', error);
      }
    },
    [
      activeState,
      age.life_stage,
      avatar_file_name,
      breed,
      filteredAndSortedPets,
      handleCreatePetOwnerAccount,
      handlePetAdoptionFn,
      id,
      name,
      pet.age.life_stage,
      pet.avatar_file_name,
      pet.breed,
      pet.gender,
      pet.name,
      pet.shelter_name_common,
      pet_passport_id,
      shelter_name_common,
      trueOnCloseFn,
      updateFilteredAndSortedPets,
    ]
  );

  return {
    handleFullAdoption,
    isCreationLoading,
    isCreationCompleted,
    isAdoptionLoading,
    isAdoptionCompleted,
    creationErrors,
    trueOnCloseFn,
  };
}
