// eslint-disable-next-line import/no-extraneous-dependencies
import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { setupListeners } from '@reduxjs/toolkit/query';
import { organizationSlice } from './organization/organizationSlice';

// Configure the Redux store
export const store = configureStore({
  reducer: {
    organization: organizationSlice.reducer,
  },
});

// Set up RTK Query listeners (optional, for cache management, etc.)
setupListeners(store.dispatch);
