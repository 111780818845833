// eslint-disable-next-line import/no-extraneous-dependencies
import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  pets: [],
};

export const organizationSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    initOrganization: (state) => {
      state.list = [];
      state.search = '';
    },
    setOrganizationPets: (state, action) => {
      state.pets = action.payload;
    },
    resetOrganization: () => initialState,
  },
});

const selectSlice = (state) => state.organization || initialState;
export const selectOrganizationPets = createSelector([selectSlice], (state) => state.pets);

export const { initOrganization, resetOrganization, setOrganizationPets } =
  organizationSlice.actions;

export default organizationSlice.reducer;
